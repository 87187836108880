//
// DONE: add production variables
//
// OBS we are alwais running development also in production
//
const prod = {
    url: {
     API_URL: "https://plaid-api.k8s-prod.pharmb.io"
    }
   };
  
const dev = {
    url: {
     API_URL: "https://plaid-api.k8s-prod.pharmb.io" 
    }
   };
   
export const config = process.env.NODE_ENV === "development" ? dev : prod;
