import React from "react";
import styled from "styled-components";
import DownloadResultCsv from "./DownloadResultCsv";
import DownloadResultJson from "./DownloadResultJson";

const StyledFixedButton = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundColors.downloadButtonBlue}; 
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin:25px;
  border-radius: 4px;
  width:100px;
`;

/**
 * Render the position and container of a button that downloads the output result as csv or json file
 *
 * @param props.plates each plate generated by the minizinc model
 * @param props.type csv or json file
 * --- if type is json ---
 * @param props.data the compound/well objects
 * @param props.rows amount of rows
 * @param props.cols amount of cols
 * @param props.sizeEmptyEdge num of empty edges
 * @param props.controls the names of the control compounds
 */
const DownloadButton = (props) => {
  return (
    <StyledFixedButton>
      {props.type === "csv" ? <DownloadResultCsv plates={props.plates} single={false} /> : <DownloadResultJson data={props.data}
        rows={props.rows}
        cols={props.cols}
        sizeEmptyEdge={props.sizeEmptyEdge}
        controls={props.controls}
        ></DownloadResultJson>}
    </StyledFixedButton>
  );
};

export default DownloadButton;
